import {
  oss,
  subjectInfo,
  subjectOneInfo,
  subjectSave,
  subjectUpdate,
  subjectClose,
  subjectCopy,
  subjectPublish,
  subjectRecover,
  subjectGetItemIds,
  subjectitemInfo,
  subjectitemList,
  subjectitemClose,
  subjectitemRecover,
  subjectitemSave,
  subjectitemUpdate,
  subjectoptionSave,
  subjectoptionUpdate,
  subjectoptionInfo,
  subjectscoreList,
  subjectscoreInfo,
  subjectscoreRecover,
  subjectscoreClose,
  subjectscoreSave,
  subjectscoreUpdate,
  subjectscaleList,
  subjectscaleInfo,
  subjectscaleClose,
  subjectscaleRecover,
  subjectscaleSaves,
} from '@/api/manager.js'

const state = {}

const mutations = {}

const actions = {
  oss(content, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("file", data)
      oss(formData).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectOneInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectOneInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectSave(content,data) {
    return new Promise((resolve, reject) => {
      subjectSave(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectUpdate(content,data) {
    return new Promise((resolve, reject) => {
      subjectUpdate(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectClose(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectClose(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectCopy(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectCopy(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectPublish(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectPublish(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectRecover(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectRecover(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectGetItemIds(content,data) {
    return new Promise((resolve, reject) => {
      subjectGetItemIds(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
//------------------------
  subjectitemInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectitemInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectitemList(content,data) {
    return new Promise((resolve, reject) => {
      subjectitemList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectitemClose(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectitemClose(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectitemRecover(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectitemRecover(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectitemSave(content,data) {
    return new Promise((resolve, reject) => {
      subjectitemSave(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectitemUpdate(content,data) {
    return new Promise((resolve, reject) => {
      subjectitemUpdate(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //----
  subjectoptionSave(content,data) {
    return new Promise((resolve, reject) => {
      subjectoptionSave(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectoptionUpdate(content,data) {
    return new Promise((resolve, reject) => {
      subjectoptionUpdate(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectoptionInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectoptionInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  //---
  subjectscoreList(content,data) {
    return new Promise((resolve, reject) => {
      subjectscoreList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscoreInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectscoreInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscoreRecover(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectscoreRecover(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscoreClose(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectscoreClose(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscoreSave(content,data) {
    return new Promise((resolve, reject) => {
      subjectscoreSave(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscoreUpdate(content,data) {
    return new Promise((resolve, reject) => {
      subjectscoreUpdate(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
//--------
  subjectscaleList(content,data) {
    return new Promise((resolve, reject) => {
      subjectscaleList(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscaleInfo(content,data) {
    return new Promise((resolve, reject) => {
      subjectscaleInfo(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscaleClose(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectscaleClose(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscaleRecover(content,data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append("id", data)
      subjectscaleRecover(formData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  subjectscaleSaves(content,data) {
    return new Promise((resolve, reject) => {
      subjectscaleSaves(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}