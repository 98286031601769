import request from '@/utils/request'
import {
    OSS,
    SUBJECT_INFO,
    SUBJECT_ONE_INFO,
    SUBJECT_SAVE,
    SUBJECT_UPDATE,
    SUBJECT_CLOSE,
    SUBJECT_COPY,
    SUBJECT_PUBLISH,
    SUBJECT_RECOVER,
    SUBJECT_GETITEMIDS,
    SUBJECTITEM_INFO,
    SUBJECTITEM_LIST,
    SUBJECTITEM_CLOSE,
    SUBJECTITEM_RECOVER,
    SUBJECTITEM_SAVE,
    SUBJECTITEM_UPDATE,
    SUBJECTOPTION_SAVE,
    SUBJECTOPTION_UPDATE,
    SUBJECTOPTION_INFO,
    SUBJECTSCORE_LIST,
    SUBJECTSCORE_INFO,
    SUBJECTSCORE_CLOSE,
    SUBJECTSCORE_RECOVER,
    SUBJECTSCORE_SAVE,
    SUBJECTSCORE_UPDATE,
    SUBJECTSCALE_LIST,
    SUBJECTSCALE_INFO,
    SUBJECTSCALE_CLOSE,
    SUBJECTSCALE_RECOVER,
    SUBJECTSCALE_SAVES,
    // SUBJECTSCALE_UPDATE,
} from "@/api/url";

export function oss(data) {
    return request({
        url: OSS,
        method: 'post',
        data
    })
}
export function subjectInfo(data) {
    return request({
        url: SUBJECT_INFO,
        method: 'get',
        params: data
    })
}
export function subjectOneInfo(data) {
    return request({
        url: SUBJECT_ONE_INFO,
        method: 'get',
        params: data
    })
}
export function subjectSave(data) {
    return request({
        url: SUBJECT_SAVE,
        method: 'post',
        data
    })
}
export function subjectGetItemIds(data) {
    return request({
        url: SUBJECT_GETITEMIDS,
        method: 'get',
        params: data
    })
}
export function subjectUpdate(data) {
    return request({
        url: SUBJECT_UPDATE,
        method: 'post',
        data
    })
}
export function subjectClose(data) {
    return request({
        url: SUBJECT_CLOSE,
        method: 'post',
        data
    })
}
export function subjectCopy(data) {
    return request({
        url: SUBJECT_COPY,
        method: 'post',
        data
    })
}
export function subjectPublish(data) {
    return request({
        url: SUBJECT_PUBLISH,
        method: 'post',
        data
    })
}
export function subjectRecover(data) {
    return request({
        url: SUBJECT_RECOVER,
        method: 'post',
        data
    })
}
//---
export function subjectitemInfo(params) {
    return request({
        url: SUBJECTITEM_INFO,
        method: 'get',
        params
    })
}
export function subjectitemList(params) {
    return request({
        url: SUBJECTITEM_LIST,
        method: 'get',
        params
    })
}
export function subjectitemClose(data) {
    return request({
        url: SUBJECTITEM_CLOSE,
        method: 'post',
        data
    })
}
export function subjectitemRecover(data) {
    return request({
        url: SUBJECTITEM_RECOVER,
        method: 'post',
        data
    })
}
export function subjectitemSave(data) {
    return request({
        url: SUBJECTITEM_SAVE,
        method: 'post',
        data
    })
}
export function subjectitemUpdate(data) {
    return request({
        url: SUBJECTITEM_UPDATE,
        method: 'post',
        data
    })
}

export function subjectoptionSave(data) {
    return request({
        url: SUBJECTOPTION_SAVE,
        method: 'post',
        data
    })
}
export function subjectoptionUpdate(data) {
    return request({
        url: SUBJECTOPTION_UPDATE,
        method: 'post',
        data
    })
}
export function subjectoptionInfo(data) {
    return request({
        url: SUBJECTOPTION_INFO,
        method: 'post',
        data
    })
}
//---
export function subjectscoreList(params) {
    return request({
        url: SUBJECTSCORE_LIST,
        method: 'get',
        params
    })
}
export function subjectscoreInfo(params) {
    return request({
        url: SUBJECTSCORE_INFO,
        method: 'get',
        params
    })
}
export function subjectscoreClose(data) {
    return request({
        url: SUBJECTSCORE_CLOSE,
        method: 'post',
        data
    })
}
export function subjectscoreRecover(data) {
    return request({
        url: SUBJECTSCORE_RECOVER,
        method: 'post',
        data
    })
}
export function subjectscoreSave(data) {
    return request({
        url: SUBJECTSCORE_SAVE,
        method: 'post',
        data
    })
}
export function subjectscoreUpdate(data) {
    return request({
        url: SUBJECTSCORE_UPDATE,
        method: 'post',
        data
    })
}
//--
export function subjectscaleList(params) {
    return request({
        url: SUBJECTSCALE_LIST,
        method: 'get',
        params
    })
}
export function subjectscaleInfo(params) {
    return request({
        url: SUBJECTSCALE_INFO,
        method: 'get',
        params
    })
}
export function subjectscaleClose(data) {
    return request({
        url: SUBJECTSCALE_CLOSE,
        method: 'post',
        data
    })
}
export function subjectscaleRecover(data) {
    return request({
        url: SUBJECTSCALE_RECOVER,
        method: 'post',
        data
    })
}
export function subjectscaleSaves(data) {
    return request({
        url: SUBJECTSCALE_SAVES,
        method: 'post',
        data
    })
}
