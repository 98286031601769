import{
    orderInfoList,
    orderInfoListOption,
    orderstatisList
}from '@/api/record.js'

const state = {}

const mutations = {}

const actions = {
    orderInfoList(content,data) {
        return new Promise((resolve, reject) => {
            orderInfoList(data).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
        })
    },
    orderInfoListOption(content,data) {
        return new Promise((resolve, reject) => {
            orderInfoListOption(data).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
        })
    },
    orderstatisList(content,data) {
        return new Promise((resolve, reject) => {
            orderstatisList(data).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }