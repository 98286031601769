import axios from 'axios'
import { ElMessage } from "element-plus";
import { CODE } from "@/config"
import {getToken} from "@/utils/auth"
//创建axios实例
let service = axios.create({
    timeout: 5000
})

service.interceptors.request.use(
    config =>{
        if(getToken()){
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    err =>{
        return Promise.reject(err)
    }
)

service.interceptors.response.use(
    response =>{
        let res = response.data
        // if(res.code === '401'){
        //      location.href = '/login'
        // } 
        if(res.code !== CODE){
            if(res.message){
                ElMessage({
                    type: "warning",
                    message: res.message,
                });
            }else{
                ElMessage({
                    type: "warning",
                    message: res.msg,
                });
            }
            
        }
        return Promise.resolve(res)
    },
    err => {
        return Promise.reject(err)
    }
)
export default service