import { createRouter ,createWebHashHistory } from 'vue-router'

import Layout from '@/layout'
const routes = [
  {
    path: '/layout',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '/manage',
        name: 'manage',
        component: () => import('@/views/manage/manage.vue')
      },
      {
        path: '/edit',
        name: 'edit',
        component: () => import('@/views/manage/edit.vue')
      },
      {
        path: '/record',
        name: 'record',
        component: () => import('@/views/record/record.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/user.vue')
      }
    ]
  },
  {
    path: '/',
    redirect:'manage'
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
