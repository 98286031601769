<template>
  <el-container style="height: 100vh;">
    <!-- <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
      <p class="manage">心辰测评管理平台</p>
      <el-menu >
        <el-menu-item index="1" @click="goPage(1)">
            <span>评测管理</span>
        </el-menu-item>
        <el-menu-item index="2" @click="goPage(2)">
            <span>评测记录</span>
        </el-menu-item>
        <el-menu-item index="3" @click="goPage(3)">
            <span>用户测评数据</span>
        </el-menu-item> 
      </el-menu>
    </el-aside> -->

    <el-container>
      <!-- <el-header class="header">
          <span style="font-size:25px;color: #222222;">{{nameRef}}</span>
          <div style="margin-right: 35px">
             <el-dropdown>
                <el-icon style="margin-right: 15px"><setting /></el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
            </el-dropdown>
            <span>管理员</span>
          </div>
      </el-header> -->

      <el-main style="background: #f0f2f5;">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { defineComponent,ref} from 'vue'
// import { Setting } from '@element-plus/icons'
import {useRouter} from 'vue-router'

export default defineComponent({
  // components: {
  //   Setting,
  // },
  setup(){
    const nameRef = ref('测评管理')
    const router = useRouter()
    const goPage = (i) =>{
      if(i===1){
        router.push({ path: 'manage' })
        nameRef.value = "测评管理"

      }
      if(i===2){
        router.push({ path: 'record' })
        nameRef.value = "测评记录"
      }
      if(i===3){
        router.push({ path: 'user' })
        nameRef.value = "用户测评数据"
      }
    }
    return{
      goPage,
      nameRef
    }
  }
})
</script>

<style>
.el-header {
  background-color: rgb(238, 241, 246);
  color: var(--el-text-color-primary);
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.el-aside {
  color: var(--el-text-color-primary);
}
.manage{
  /* background-color: white; */
  margin-top: 20px;
  line-height: 100%;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 60px;
  border-right: 2px solid #F0F0F0;
}
</style>
