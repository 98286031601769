import request from '@/utils/request'
import{
    ORDERINFO_LISTORDER,
    ORDERINFO_LISTOPTION,
    ORDERSTATIS_LIST,
}from './url'

export function orderInfoList(data) {
    return request({
        url: ORDERINFO_LISTORDER,
        method: 'get',
        params: data
    })
}
export function orderInfoListOption(data) {
    return request({
        url: ORDERINFO_LISTOPTION,
        method: 'get',
        params: data
    })
}

export function orderstatisList(data) {
    return request({
        url: ORDERSTATIS_LIST,
        method: 'get',
        params: data
    })
}
